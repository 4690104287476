import React, { useEffect, useState } from "react";
import { SitePage } from "app/layout";
import { graphql } from "gatsby";
import { QuestionSection } from "../app/survey/questions";
import { getTopicSuggestionTest } from "../app/topics/getTopicSuggestion";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControlLabel,
  Paper,
  Switch,
  Typography,
  TextField,
  Button,
} from "@mui/material";

type Survey = {
  _total: number;
  _completed: number;
  _step: number;
} & Record<string, any>;

type TestProps = {
  data: {
    topics: {
      nodes: Array<data.Topic>;
    };
    baseline: {
      nodes: Array<data.Survey>;
    };
    followup: {
      nodes: Array<data.Survey>;
    };
  };
};

// Keys to exclude from random generation
const excludedKeys: string[] = [
  "S1#intro",
  "S3#intro",
  // Add other keys as needed
];

export default function Calc(props: TestProps) {
  const {
    data: {
      topics: { nodes: topics },
      baseline: { nodes: baseline },
      followup: { nodes: followup },
    },
  } = props;

  const [sections, setSections] = useState<QuestionSection[]>([]);
  const [scores, setScores] = useState<{ [key: string]: number }>({});
  const [entries, setEntries] = useState<state.Survey[]>([]);

  useEffect(() => {
    if (baseline.length > 0) {
      const firstNode = baseline[0];
      const sectionList = firstNode.sections.data.filter(
        (section) => section.type !== "paragraph"
      );
      console.log("sections", sectionList);
      setSections(sectionList);
    }
  }, [baseline]);

  const handleScoreChange = (key: string, value: string) => {
    setScores((prevScores) => ({
      ...prevScores,
      [key]: Number(value),
    }));
  };

  const handleSubmit = () => {
    console.log("Scores:", scores);
    // Perform any action with the scores
    const survey: Survey = {
      _total: 100,
      _completed: 80,
      _step: 2,
      ...scores,
    };
    console.log("survey", survey);
    const entries = getTopicSuggestionTest(survey);
    console.log("entries", entries);
    setEntries(entries);
  };

  return (
    <SitePage>
      <Paper style={{ padding: "20px" }}>
        {sections.length > 0 && (
          <>
            {sections.map((section) => (
              <div key={section.key} style={{ marginBottom: "15px" }}>
                <TextField
                  label={`Score for ${section.key}`}
                  variant="outlined"
                  fullWidth
                  type="number"
                  onChange={(e) => handleScoreChange(section.key, e.target.value)}
                />
              </div>
            ))}
            <Button variant="contained" color="primary" onClick={handleSubmit}>
              Submit Scores
            </Button>
          </>
        )}
      </Paper>

      {entries.length > 0 && (
        <div style={{ marginTop: "20px" }}>
          <TopicList entries={entries} />
        </div>
      )}
    </SitePage>
  );
}

const TopicList = ({ entries }) => {
  const getBackgroundColor = (topicKey) => {
    if (topicDynamicPart2.includes(topicKey)) {
      return "#d9ead3";
    } else if (topicDynamicPart4.includes(topicKey)) {
      return "#fce5cd";
    } else if (topicDynamicPart5.includes(topicKey)) {
      return "#e6b8af";
    }
    return "#FFFFFF";
  };

  return (
    <div>
      {entries.map((topic, index) => (
        <Accordion key={index} style={{ backgroundColor: getBackgroundColor(topic.topicKey) }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <FormControlLabel
              control={
                <Switch checked={topic.recommend} disabled={topic.pin} name={topic.topicKey} />
              }
              label={topic.topicKey}
            />
          </AccordionSummary>
          <AccordionDetails>
            <Paper style={{ padding: "10px", width: "100%" }}>
              <Typography variant="body2" style={{ marginBottom: "8px" }}>
                Topic Key: {topic.topicKey}
              </Typography>
              <Typography variant="body2" style={{ marginBottom: "8px" }}>
                Sort Score: {topic.sortScore}
              </Typography>
              <Typography variant="body2" style={{ marginBottom: "8px" }}>
                Score based on : {scoreBasedSection(topic.topicKey)}
              </Typography>
            </Paper>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

export const query = graphql`
  query Calc($project: String = "pip-ka") {
    topics: allStrapiTopic(filter: { project: { name: { eq: $project } } }) {
      nodes {
        ...TestTopicFragment
      }
    }
    baseline: allStrapiSurvey(
      filter: { project: { name: { eq: $project } }, scope: { eq: "baseline" } }
      sort: { fields: seq }
    ) {
      nodes {
        ...TestSurveyFragment
      }
    }

    followup: allStrapiSurvey(
      filter: { project: { name: { eq: $project } }, scope: { eq: "followup" } }
      sort: { fields: seq }
    ) {
      nodes {
        ...TestSurveyFragment
      }
    }
  }

  fragment TestTopicFragment on STRAPI_TOPIC {
    key: slug
    seq
    title
    description
    cover {
      localFile {
        childImageSharp {
          gatsbyImageData(aspectRatio: 1.25, height: 800)
        }
      }
    }
    contents {
      key: slug
      seq
      title
      optional
      goals
      activityKeys {
        data: strapi_json_value
      }
    }
  }

  fragment TestSurveyFragment on STRAPI_SURVEY {
    key
    seq
    scope
    title
    tint
    cover {
      localFile {
        publicURL
      }
    }
    extraStyle {
      left
      bottom
      height
    }
    sections {
      data: strapi_json_value {
        key
        title
        type
        scales
        options
        required
        questions {
          key
          label
          type
          scales
          options
          answers
          variant
          content
          cond
          required
          title
        }
      }
    }
  }
`;
