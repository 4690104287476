import sum from 'lodash/sum';
import { evaluateParcads1 } from 'app/feedback/Parcads1';
import { evaluateParcads2 } from 'app/feedback/Parcads2';
import { evaluateParcads3 } from 'app/feedback/Parcads3';
import { evaluateParcads4 } from 'app/feedback/Parcads4';
import { evaluateParcads5 } from 'app/feedback/Parcads5';
import { evaluateParcads6 } from 'app/feedback/Parcads6';
import { evaluateParcads8 } from 'app/feedback/Parcads8';
import { evaluateParcads7 } from 'app/feedback/Parcads7';
import { evaluateParcads9 } from 'app/feedback/Parcads9';
import { evaluateParcads10 } from 'app/feedback/Parcads10';

import type { state } from 'app';

export type Entry = {
  topicKey: string;
  pin: boolean;
  recommend: boolean;
};

const getEntries = (parcads: state.Survey): Array<Entry & { sortScore: number }> => {
  const score1 = evaluateParcads1(parcads);
  const score2 = evaluateParcads2(parcads);
  const score3 = evaluateParcads3(parcads);
  const score4 = evaluateParcads4(parcads);
  const score5 = evaluateParcads5(parcads);
  const score6 = evaluateParcads6(parcads);
  const score7 = evaluateParcads7(parcads);
  const score8 = evaluateParcads8(parcads);
  const score9 = evaluateParcads9(parcads);
  const score10 = evaluateParcads10(parcads);

  const psesaQ11Score = parcads['PSES-A#Q11'];
  const psesaQ14Score = parcads['PSES-A#Q14'];
  return [
    {
      topicKey: 'pka-affection-acceptance',
      pin: true,
      recommend: true,
      sortScore: 1,
    }, //1
    {
      topicKey: 'pka-behaviour',
      pin: true,
      recommend: true,
      sortScore: 2,
    }, //2
    {
      topicKey: 'pka-autonomy',
      pin: true,
      recommend: true,
      sortScore: 3 + sum(score2) / score2.length,
    }, //3
    {
      topicKey: 'pka-everyday-emotions',
      pin: true,
      recommend: true,
      sortScore: 3 + sum(score7) / score7.length,
    }, //4
    {
      topicKey: 'pka-tough-emotions',
      pin: true,
      recommend: true,
      sortScore: 3 + sum(score9) / score9.length,
    }, //5
    {
      topicKey: 'pka-yourself',
      pin: true,
      recommend: true,
      sortScore: 6,
    }, //6
    {
      topicKey: 'pka-connect',
      pin: false,
      recommend: sum(score1.slice(4)) <= 2,
      sortScore: 7 + sum(score1.slice(4)) / 4,
    }, //7
    {
      topicKey: 'pka-supportive-relationships',
      pin: false,
      recommend: sum(score3) <= 5,
      sortScore: 7 + sum(score3) / score3.length,
    }, //8
    {
      topicKey: 'pka-family-rules',
      pin: false,
      recommend: sum(score4) <= 6,
      sortScore: 7 + sum(score4) / score4.length,
    }, //9
    {
      topicKey: 'pka-health-habits',
      pin: false,
      recommend: sum(score5) <= 6, // need to check <= 6 it should be based on docs
      sortScore: 7 + sum(score5) / score5.length,
    }, //10
    {
      topicKey: 'pka-conflict',
      pin: false,
      recommend: sum(score6) <= 7,
      sortScore: 7 + sum(score6) / score6.length,
    }, //11
    {
      topicKey: 'pka-goals',
      pin: false,
      recommend: sum(score8) <= 6,
      sortScore: 7 + sum(score8) / score8.length,
    }, //12
    {
      topicKey: 'pka-seek-help',
      pin: false,
      recommend: sum(score10) <= 3,
      sortScore: 7 + sum(score10) / score10.length,
    }, //13
    {
      topicKey: 'pka-fundamentals',
      pin: false,
      recommend: psesaQ11Score < 4,
      sortScore: 14 + psesaQ11Score / 4,
    }, //14
    {
      topicKey: 'pka-sensory-needs',
      pin: false,
      recommend: psesaQ14Score < 4,
      sortScore: 14 + psesaQ14Score / 4,
    }, //15
  ].sort((a, b) => a.sortScore - b.sortScore);
};

export function getTopicSuggestion(userSurveys: Record<string, state.Survey>) {
  const parcads = userSurveys['pka-parcads'];
  return getEntries(parcads);
}

export function getTopicSuggestionTest(survey: state.Survey) {
  return getEntries(survey);
}
